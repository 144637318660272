<template>
  <div>
    <div class="confirmOrderContainer pb20">
      <div class="pageTitle fc-333 ff-ss fs-14 font-bold">Shipping address</div>
      <div class="pageContentView">
        <div class="pageContentLeft">
          <div class="formView br-12">
            <a-form-model ref="formData" :model="formData" :rules="formRule" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
              <a-form-model-item label="Full Name" style="margin-bottom: 0;" required>
                <a-form-model-item
                  :style="{ display: 'inline-block', width: 'calc(50% - 0.75vw)' }"
                  prop="firstName"
                >
                  <a-input
                    v-model="formData.firstName"
                    :max-length="20"
                    placeholder="First name"
                  />
                </a-form-model-item>
                <span :style="{ display: 'inline-block', width: '1.5vw', textAlign: 'center' }">
                &nbsp;&nbsp;
                </span>
                <a-form-model-item
                  prop="lastName"
                  :style="{ display: 'inline-block', width: 'calc(50% - 0.75vw)' }"
                >
                  <a-input
                    v-model="formData.lastName"
                    :max-length="20"
                    placeholder="last name"
                  />
                </a-form-model-item>
              </a-form-model-item>
              <a-form-model-item
                label="Email"
                prop="email"
              >
                <a-input
                  v-model="formData.email"
                  :max-length="100"
                  placeholder="Email"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Phone"
                prop="phone"
              >
                <a-input
                  v-model="formData.phone"
                  :max-length="50"
                  placeholder="Phone"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Country"
                prop="country"
              >
                <a-select
                  v-model="formData.country"
                  class="current-selection--single"
                  placeholder="Country"
                  show-search
                  option-filter-prop="children"
                  :filter-option="countryFilterOption"
                  autocomplete="off"
                  @change="onCountryChange"
                >
                  <a-select-option v-for="(item) in countryList" :key="item.id" :value="item.id">
                    {{ item.enName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="State/Provnce"
                prop="state"
              >
                <a-select
                  v-show="provinceList.length > 0"
                  v-model="formData.state"
                  class="current-selection--single"
                  show-search
                  placeholder="State/Province*"
                  autocomplete="off"
                >
                  <a-select-option
                    v-for="(item, index) in provinceList"
                    :key="index"
                    :value="item.enName"
                  >{{ item.enName }}</a-select-option>
                </a-select>
                <a-input
                  v-show="provinceList.length === 0"
                  v-model="formData.state"
                  class="current-selection--single"
                  placeholder="State/Province*"
                  type="text"
                  autocomplete="off"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Address"
                prop="address"
              >
                <a-input
                  v-model="formData.address"
                  :max-length="100"
                  placeholder="Address"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Address2"
                prop="address2"
              >
                <a-input
                  v-model="formData.address2"
                  :max-length="100"
                  placeholder="Address2"
                />
              </a-form-model-item>
              <a-form-model-item
                label="City"
                prop="city"
              >
                <a-input
                  v-model="formData.city"
                  :max-length="50"
                  placeholder="City"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Postal/zip code*"
                prop="postal"
              >
                <a-input
                  v-model="formData.postal"
                  :max-length="50"
                  placeholder="Postal/zip code*"
                />
              </a-form-model-item>
              <a-form-model-item
                label="External Order ID"
                prop="externalOrderId"
              >
                <a-input
                  v-model="formData.externalOrderId"
                  :max-length="50"
                  placeholder="External Order ID"
                />
              </a-form-model-item>
              <a-form-model-item
                label="Note"
                prop="note"
              >
                <a-input
                  v-model="formData.note"
                  :max-length="100"
                  placeholder="Note"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div class="productsView br-12">
            <div class="font16 ff-ss fc-333 font-bold pb20">Order review</div>
            <div>
              <a-table
                row-key="id"
                :columns="columns"
                :data-source="carts"
                :pagination="false"
              >
                <div slot="Product" slot-scope="text, record" class="d-flex a-center tableProductView">
                  <div class="productImg" @click="showProductImages([record.mainImageUrl])"><img class="productImg" :src="record.mainImageUrl"></div>
                  <div class="productMessageView">
                    <div class="fs-14 ff-ss " style="font-weight: bold;">{{ record.blankProductName }}</div>
                    <option-info v-if="record.option1Name" :option-name="record.option1Name" :option-value="record.option1Value" />
                    <option-info v-if="record.option2Name" :option-name="record.option2Name" :option-value="record.option2Value" />
                    <option-info v-if="record.option3Name" :option-name="record.option2Name" :option-value="record.option3Value" />
                  </div>
                </div>
                <div slot="price" slot-scope="text, record" class="fs-14 fc-33 ff-ss font-bold">
                  {{ record.price | MoneyFormat }}
                </div>
                <div slot="quantity" slot-scope="text, record" class="fs-14 fc-33 ff-ss font-bold">
                  {{ record.quantity }}
                </div>
                <div slot="total" slot-scope="text, record" class="fs-14 fc-33 ff-ss font-bold">
                  {{ record.quantity * record.price | MoneyFormat }}
                </div>
              </a-table>
            </div>
          </div>
        </div>
        <div class="pageContentRight">
          <div class="shippingMethodView br-12">
            <div class="font16 ff-ss fc-333 font-bold">Shipping method</div>
            <div class="shippingMethodContent">
              <div
                v-for="item in shippingTypeList"
                :key="item.key"
                :class="item.key===logisticsMethodId?'shippingItemSelect':'shippingItemNoSelect'"
                class="shippingItemView mt15"
                @click.stop="handleShippingTypeChange(item,$event)"
              >
                <div class="pb15">
                  <a-radio :checked="item.key===logisticsMethodId" @change="handelRadioChange"> <span class="fs-14 fc-333 ff-ss shippingItemText">{{ item.name }}</span> </a-radio>
                </div>
                <div class="fc-999 fs-14 ff-ss">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div class="summaryView br-12">
            <div class="font16 ff-ss fc-333 font-bold">Summary</div>
            <div class="summaryItem fs-14 fc-333 ff-ss pt25">
              <div>Product Price(1 items)</div>
              <div class="ml-auto">${{ currentPriceInfo.production | moneyFormat }}</div>
            </div>
            <div class="summaryItem fs-14 fc-333 ff-ss pt25">
              <div>Shipping</div>
              <div class="ml-auto">${{ currentPriceInfo.shipping | moneyFormat }}</div>
            </div>
            <div class="summaryItem fs-14 fc-333 ff-ss pt25">
              <div>Discount</div>
              <div class="ml-auto">${{ currentPriceInfo.discount | moneyFormat }}</div>
            </div>
            <a-divider />
            <div class="summaryItem fs-14 fc-333 ff-ss">
              <div>Total</div>
              <div class="ml-auto font-bold font16 ff-ss fc-333">${{ currentPriceInfo.total | moneyFormat }}</div>
            </div>
            <div v-if="stripeEnable" class="w-100 pt25">
              <a-button
                size="large"
                :loading="payLoadiing"
                class="w-100"
                type="primary"
                @click="toPay"
              >Pay With Credit Card</a-button>
            </div>

          </div>
        </div>
      </div>
      <onePay
        v-if="stripeEnable"
        ref="onePay"
        @doClose="onPayClose"
        @paySuccess="onPaySuccess"
        @payFail="payFail"
      />
      <look-imgs-modal
        :banner-list="previewImages"
        height="470"
        :visible.sync="showPreviewImage"
      />
    </div>
  </div>
</template>

<script>
import { cartList } from '@/api/cart'
import OptionInfo from './components/option_info'
import { mapGetters } from 'vuex'
import onePay from '@/components/onePayNew'
import { createPaypal, executePaypal } from '@/api/pay'
import { countrys, provinces } from '@/api/public'
import LookImgsModal from '@/views/my_products/components/look_imgs_modal'
import { calculate, createFromCart, updateShopping } from '@/api/order'
const columns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    scopedSlots: { customRender: 'Product' }
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' }
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    scopedSlots: { customRender: 'total' }
  }
]

const formRule = {
  firstName: [
    {
      required: true,
      message: 'First name is required'
    }
  ],
  lastName: [
    {
      required: true,
      message: 'Last name is required'
    }
  ],
  email: [],
  phone: [
    {
      required: true,
      message: 'Phone is required'
    }
  ],
  country: [
    {
      required: true,
      message: 'Country is required'
    }
  ],
  state: [
    {
      required: true,
      message: 'State is required'
    }
  ],
  address: [
    {
      required: true,
      message: 'Address is required'
    },
    { max: 40, message: 'Max length 40' }
  ],
  address2: [],
  city: [
    {
      required: true,
      message: 'City is required'
    },
    { max: 40, message: 'Max length 40' }
  ],
  postal: [
    {
      required: true,
      message: 'ZIP code is required'
    }
  ],
  note: [{ max: 200, message: 'Max length 200' }],
  externalOrderId: [{ max: 200, message: 'Max length 200' }]
}
export default {
  components: {
    OptionInfo,
    onePay,
    LookImgsModal
  },
  props: {
    ids: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      previewImages: [],
      showPreviewImage: false,
      provinceList: [],
      countryList: [],
      formRule,
      formData: {
        country: null,
        state: null,
        phone: null,
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        postal: null,
        note: null,
        externalOrderId: null
      },
      columns,
      logisticsMethodId: null,
      carts: [],
      onePayShow: false,
      countryId: null,
      priceInfos: [],
      orderId: null,
      payLoadiing: false
    }
  },
  computed: {
    ...mapGetters('User', {
      paypalEnable: 'getPaypalEnable',
      stripeEnable: 'getStripeEnable'
    }),
    shippingTypeList() {
      if (!this.priceInfos) {
        return []
      }
      if (!this.priceInfos.length) {
        return []
      }
      var rs = []
      for (const priceInfo of this.priceInfos) {
        rs.push({
          key: priceInfo.logisticsMethodId,
          name: priceInfo.logisticsMethodName,
          description: `Average fulfillment time ${priceInfo.deliveryTime}`
        })
      }
      return rs
    },
    currentPriceInfo() {
      if (!this.priceInfos) {
        return {}
      }
      if (!this.priceInfos.length) {
        return {}
      }
      for (const priceInfo of this.priceInfos) {
        if (priceInfo.logisticsMethodId === this.logisticsMethodId) {
          return priceInfo
        }
      }
      return {}
    }
  },
  mounted() {
    this.getCartList()
    this.getCountrys()
    /* if (this.paypalEnable) {
      this.paypalReady()
    }*/
  },
  methods: {
    showProductImages(record) {
      this.previewImages = record
      this.showPreviewImage = true
    },
    async getCartList() {
      var ids = this.ids.split(',')
      var postData = {}
      postData.ids = ids
      postData.current = 1
      postData.size = 100
      const response = await cartList(postData)
      if (response.code === 200) {
        var carts = []
        if (response.data.records) {
          for (const record of response.data.records) {
            var cart = Object.assign({}, record)
            if (cart.option1Value) {
              cart.option1Value = JSON.parse(cart.option1Value)
            }
            if (cart.option2Value) {
              cart.option2Value = JSON.parse(cart.option2Value)
            }
            if (cart.option3Value) {
              cart.option3Value = JSON.parse(cart.option3Value)
            }
            carts.push(cart)
          }
        }
        this.carts = carts
      }
    },
    async getCountrys() {
      const { data } = await countrys()
      this.countryList = data.countryInfoList || []
    },
    async getProvinces(value) {
      if (!value) {
        return
      }
      const { data } = await provinces({ countryId: value })
      this.provinceList = data.provinceInfoList || []
    },

    onCountryChange(v) {
      this.formData.state = null
      this.getProvinces(v)
      this.calculatePrice()
    },
    async calculatePrice() {
      var itemList = []
      for (const cart of this.carts) {
        var item = {}
        item.blankProductSkuId = cart.blankProductSkuId
        item.num = cart.quantity
        itemList.push(item)
      }
      var postData = {}
      postData.countryId = this.formData.country
      postData.itemList = itemList
      const { data } = await calculate(postData)
      this.priceInfos = data.priceInfos || []
      if (this.priceInfos && this.priceInfos.length) {
        this.logisticsMethodId = this.priceInfos[0].logisticsMethodId
      }
    },
    checkPhone(rule, value, callback) {
      const phoneIsTrue = true
      // TODO 验证phone格式
      // let reg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      // phoneIsTrue = reg.test(value);
      if (!value) {
        callback('Please input your Phone')
        return
      }
      if (phoneIsTrue) {
        callback()
        return
      }
      callback('Phone format error!')
    },
    checkEmail(rule, value, callback) {
      const reg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
      const emailIsTrue = reg.test(value)
      if (emailIsTrue) {
        callback()
        return
      }
      callback('Mailbox format error!')
    },
    payWithCreditCard() {
      console.log('payWithCreditCard')
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    async toPay() {
      this.$refs.formData.validate(async(valid) => {
        if (!valid) {
          return
        }
        this.payLoadiing = true
        try {
          if (this.orderId) {
            await this.updateOrderShipping()
            this.onePayShow = true
            this.$refs.onePay.show({ orderIds: [this.orderId] })
            return
          }
          await this.createOrder()
          this.onePayShow = true
          this.$refs.onePay.show({ orderIds: [this.orderId] })
        } finally {
          this.payLoadiing = false
        }
      })
    },
    async createOrder() {
      var itemList = []
      for (const cart of this.carts) {
        itemList.push({ cartItemId: cart.id })
      }
      var postData = {}
      postData.firstName = this.formData.firstName
      postData.lastName = this.formData.lastName
      postData.email = this.formData.email
      postData.phone = this.formData.phone
      postData.countryId = this.formData.country
      postData.province = this.formData.state
      postData.address1 = this.formData.address
      postData.address2 = this.formData.address2
      postData.city = this.formData.city
      postData.zipCode = this.formData.postal
      postData.externalOrderId = this.formData.externalOrderId
      postData.logsiticsType = 3
      postData.logisticsMethodId = this.logisticsMethodId
      postData.note = this.formData.note
      postData.itemList = itemList
      var response = await createFromCart(postData)
      if (response.code === 200) {
        this.orderId = response.data.orderId
      } else {
        this.$message.error(response.msg)
      }
    },
    async updateOrderShipping() {
      const postData = {}
      postData.id = this.orderId
      postData.address1 = this.formData.address
      postData.address2 = this.formData.address2
      postData.city = this.formData.city
      postData.countryId = this.formData.country
      postData.email = this.formData.email
      postData.firstName = this.formData.firstName
      postData.phone = this.formData.phone
      postData.province = this.formData.state
      postData.lastName = this.formData.lastName
      postData.zipCode = this.formData.postal
      postData.logsiticsType = 3
      postData.logisticsMethodId = this.logisticsMethodId
      const { code, msg } = await updateShopping(postData)
      if (code !== 200) {
        this.$message.error(msg)
      }
    },
    handleShippingTypeChange(item, e) {
      this.logisticsMethodId = item.key
    },
    countryFilterOption(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    },
    // 选择国家
    async choiceCountrys(e) {
      this.countryId = e
      const { data } = await provinces({ countryId: e })
      this.provinceList = data.provinceInfoList || []
    },
    handelRadioChange(e) {
      e.preventDefault()
    },
    paypalReady() {
      const _this = this
      console.warn('paypal ready')
      // eslint-disable-next-line no-undef
      paypal.Button.render(
        {
          env: process.env.VUE_APP_PAY, // sandbox | production
          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,
          style: {
            size: 'large',
            color: 'blue',
            shape: 'rect'
          },
          locale: 'en_US',
          // payment() is called when the button is clicked
          payment: function() {
            return _this.ceatePayPals()
          },

          // 支付完成后
          onAuthorize: function(data, actions) {
            console.log(data, actions)
            var datas = {
              paymentId: data.paymentID, // 订单id
              payerId: data.payerID // 支付用户id
            }
            return _this.executePaypals(datas)
          }
        },
        'paypal-button-container3'
      )
    },
    // 创建paypal支付
    async ceatePayPals() {
      const { data } = await createPaypal({
        cancelUrl:
            process.env.VUE_APP_API_URL + '/unpay?orderId=' + this.orderId,
        orderIds: this.orderId,
        returnUrl:
            process.env.VUE_APP_API_URL + '/unpay?orderId=' + this.orderId
      })
      return data.payId
    },
    // 查询paypal支付是否成功
    async executePaypals(item) {
      const { data } = await executePaypal(item)
      if (data.status === 1) {
        this.$message.success('Pay success')
        // this.reload()
      } else {
        this.$message.success(data.failReason)
      }
    },
    onPayClose() {
      this.payLoadiing = false
      if (this.orderId) {
        this.callNewPage(`/unpay?orderId=${this.orderId}`)
      }
    },
    onPaySuccess() {
      this.callNewPage(`/cart`)
    },
    payFail() {

    }
  }
}
</script>

<style lang="less" scoped>
  .confirmOrderContainer{
    width: 1400px;
    margin: 0 auto;
    .p24{padding: 24px;}
    .fs-16{font-size: 16px;}
    .fs-14{font-size: 14px;}
    .ff-ss{font-family: Segoe UI-Regular, Segoe UI;}
    .fw-400{font-weight: 400;}
    .fc-333{color: #333333;}
    .fc-999{color: #999999;}
    .h-auto{height: auto !important;}
    .br-12{border-radius: 12px;}

    /deep/ .ant-input{
      background-color: #ffffff !important;
      border: 1px solid #e4e6ef !important;
    }
    /deep/ .ant-input:focus{
      border: 1px solid #b5b5c3 !important;
    }

    .current-selection--single /deep/ .ant-select-selection--single{
      // height: 39px !important;
      // line-height: 39px !important;
      background-color: #ffffff !important;
      color: #5e6278;
      border: 1px solid #e4e6ef !important;
      border-radius: 8px;
    }
    .ant-select-open /deep/ .ant-select-selection--single {
      border: 1px solid #b5b5c3 !important;
    }

    /deep/ .ant-table-thead > tr > th{
      background-color: transparent;
      border-bottom: 1px solid #EEEEEE;
    }
    /deep/ .ant-table-tbody > tr > td{
      background-color: transparent !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    /deep/ .ant-table-wrapper {
      border-bottom: none;
    }

    .ant-btn:not(.ant-btn-primary){
      background-color: #eff8ff !important;
      border-color: #009EF7 !important;
      color: #009EF7 !important;
      opacity: 0.8;
      &:hover{
        background-color: #eff8ff !important;
        border-color: #009EF7 !important;
        opacity: 1;
      }
      &:disabled{
        background-color: #eff8ff !important;
        border-color: #009EF7 !important;
        color: #999999;
        opacity: 0.5;
      }
      &:focus{
        background-color: #eff8ff !important;
        color: #7e8299 !important;
        box-shadow: none;
        opacity: 1;
      }
      &:active{
        background-color: #eff8ff !important;
        border-color: #0095f8 !important;
        opacity: 1;
      }
    }

    .pageTitle{
      padding-top: 40px;
      padding-bottom: 24px;
    }
    .pageContentView{
      display: flex;
      .pageContentLeft{
        width: 975px;
        .formView{
          padding: 24px 24px 20px 0px;
          background-color: #ffffff;
        }
        .productsView{
          margin-top: 40px;
          background-color: #ffffff;
          padding: 24px;
          .tableProductView{
            .productImg{
              width: 77px;
              height: 77px;
              border-radius: 8px;
            }
            .productMessageView{
              padding-left: 14px;
              .smallImg{
                width: 30px;
                height: 30px;
                border-radius: 4px;
              }
            }
          }
        }
      }
      .pageContentRight{
        width: calc(1400px - 975px);
        padding-left: 40px;
        .shippingMethodView{
          padding: 24px;
          background-color: #ffffff;
          .shippingMethodContent{
            .shippingItemView{
              cursor: pointer;
              // width: 300px;
              // height: 300px;
              padding: 24px;
              border-radius: 12px;
            }
            .shippingItemNoSelect{
              background-color: #FFFFFF;
              border: 1px dashed #DDDDDD;
              &:hover{
                background-color: #F2F6FF;
                border: 1px dashed #4CBBF9;
                // .shippingItemText{
                //   color: #4CBBF9;
                // }
              }
            }
            .shippingItemSelect{
              background-color: #F2F6FF;
              border: 1px dashed #4CBBF9;
            }
          }
        }
        .summaryView{
          margin-top: 40px;
          padding: 24px;
          background-color: #ffffff;
          .summaryItem{
            display: flex;
            align-items: center;

          }
        }
      }
    }
  }
</style>
