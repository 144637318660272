import { apiAxios } from '../utils/request.js'
// 订单分页列表
export const orderPage = (data, Istoast = false, Send = false) => {
  return apiAxios('post', 'shopapi/order/page', data, Istoast, Send)
}
// 订单详情
export const orderDetail = (data, Istoast = false, Send = false) => {
  return apiAxios('post', 'shopapi/order/detail', data, Istoast, Send)
}
// 计算价格
export const calculate = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/order/price/calculateForLogisticsMethod',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
// 创建订单
export const createOrder = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/order/create', data, Istoast, Send)
}
// 修改物流信息
export const updateShopping = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/order/shipping/update',
    data,
    Istoast,
    Send
  )
}
// 修改物流信息
export const chargeOffSession = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/pay/stripe/chargeOffSession',
    data,
    Istoast,
    Send
  )
}
// 订单统计
export const orderStatistics = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/order/statistics', data, Istoast, Send)
}
// 取消订单
export const orderCancel = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/order/cancel', data, Istoast, Send)
}
// 提交问题订单
export const onHold = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/order/onHold', data, Istoast, Send)
}
// 导入订单
export const ImportOrder = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/order/importOrder', data, Istoast, Send)
}
// 通过订单-进行价格计算
export const calculateForOrder = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/order/price/calculateForOrderForLogisticsMethod',
    data,
    false,
    false,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}

export const createFromCart = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/order/createFromCart',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
